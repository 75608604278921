<template>
    <div>
        <v-bottom-navigation class="height112" v-if="isNewStreamingPage">
          <v-container class="bg-surface-variant">
            <v-row no-gutters class="mtn10">
              <v-col cols="2">
                <v-sheet class="pointer">
                  <v-img v-if="user.geolocation_enable === 'No'"  v-bind:class="{ 'mt14' : user.geolocation_enable ==='No'}"
                      color="primary"
                      src="/images/map-icon.png"
                      max-width="80px"
                      width="50px"
                      height="50px"
                      v-model="detectLocation"
                      @click=callGeolocation(user.geolocation_enable)
                      >
                      </v-img>
                      <v-img v-else-if="user.geolocation_enable === 'Yes'"  v-bind:class="{ 'mt14' : user.geolocation_enable ==='Yes'}"
                      color="primary"
                      src="/images/map-icon-active.png"
                      max-width="80px"
                      width="50px"
                      height="50px"
                      v-model="detectLocation"
                      @click=callGeolocation(user.geolocation_enable)
                      >
                      </v-img>
                </v-sheet>
              </v-col>

              <v-col cols="2">
                <v-sheet class="pointer">
                  <v-img v-if="user.is_streaming_public === 'No'" v-bind:class="{ 'mt14' : user.is_streaming_public ==='No'}"
                      color="primary"
                      src="/images/post-icon.png"
                      max-width="80px"
                      width="50px"
                      height="50px"
                      @click=isStreamingPublicactive(user.is_streaming_public)
                      >
                      </v-img>

                      <v-img v-else-if="user.is_streaming_public === 'Yes'" v-bind:class="{ 'mt14' : user.is_streaming_public ==='Yes'}"
                      color="primary"
                      src="/images/post-icon-active.png"
                      max-width="80px"
                      width="50px"
                      height="50px"
                      @click=isStreamingPublicactive(user.is_streaming_public)
                      >
                      </v-img>
                </v-sheet>
              </v-col>


              <v-col cols="3">
                <v-sheet class="pointer">
                  <v-img
                      color="primary"
                      src="/images/go-live-icon.png"
                      @click="updateCurrentNavItem(liveStreamingItem)"
                      max-width="80px"

                      >
                      </v-img>
                </v-sheet>
              </v-col>

              <v-col cols="3">
                <v-sheet class="pointer">
                  <v-img class="mt14"
                      color="primary"
                      src="/images/record-icon.png"
                      width="50px"
                      height="50px"
                      max-width="80px"
                      >
                      </v-img>
                </v-sheet>
              </v-col>

              <v-col cols="2">
                <v-sheet class="pointer">
                  <v-img @mouseover="upHere = true" @mouseleave="upHere = false"   v-if="upHere === false" class="mt14"
                      id="menu-activator"
                      color="primary"
                      src="/images/share-icon.png"
                      width="50px"
                      height="50px"
                      max-width="80px"
                      >
                      </v-img>
                      <v-img v-else-if="upHere === true " @mouseleave="upHere = false" class="mt14"
                      id="menu-activator"
                      color="primary"
                      src="/images/share-icon-icon.png"
                      width="50px"
                      height="50px"
                      max-width="80px"
                      >
                      </v-img>
                  <v-menu activator="#menu-activator">
                          <v-btn dark fab bottom color="blue darken-7" small :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}/live-streaming/${user.id}`" target="_blank">
                            <v-icon>mdi-linkedin</v-icon>
                          </v-btn>
                          <v-btn dark fab bottom color="blue" small :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}/live-streaming/${user.id}`" target="_blank">
                            <v-icon>mdi-facebook</v-icon>
                          </v-btn>
                          <v-btn dark fab bottom color="green" small :href="`https://wa.me/?text=Checkout%20this%20page.%20${pageUrl}/live-streaming/${user.id}`" target="_blank">
                            <v-icon>mdi-whatsapp</v-icon>
                          </v-btn>
                          <v-btn dark fab bottom color="tertiary" small :href="`mailto:?subject=Awesomeness!&amp;body=Checkout this page!<a href='${pageUrl}'>${pageUrl}</a>`" target="_blank">
                            <v-icon>mdi-email</v-icon>
                          </v-btn>
                      </v-menu>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
          </v-bottom-navigation>


          <span v-else-if="isLiveStreamingPageByStreamer">


            <div style="display:none">
            <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">no_photography</i>
            <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">mic_off</i>
            <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">linked_camera</i>
            <i aria-hidden="true" @click="updateCurrentNavItem(stopLiveStreamingItem)"  class='v-icon notranslate material-icons-two-tone'>stop_circle</i>
            <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">volume_off</i>
            <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">flip_camera_ios</i>
            <i aria-hidden="true" class="v-icon notranslate material-icons theme--light">keyboard_arrow_down</i>
            </div>
          </span>

        <span v-else-if="isLiveStreamingPageByViewer"> </span>

          <v-bottom-navigation style="display: inline-flex;"
            v-else
            color="#ff0042"
            :value="selectedItem && selectedItem.name"
            shift
            grow
            max-width="100%"
            width="100%"
          >
          <div style="width:70%;float:left; margin-right: 30px; min-inline-size:fit-content;display:contents;">
            <search-bar
              @update-keyword="updateUriByKeyword($event)"
              btn-width="40px"
              btn-height="40px"
              width="100%"
              :is-search-bar-clicked="false"
              :is-persistent-search-bar=true
            ></search-bar>
          </div>
            <div style="width:30%;float:left; margin-right: 83px; margin-left:30px;min-inline-size:fit-content;display:contents;">
            <img v-for="item in items"
              :key="item.name"
              :value="item.name"
              :disabled="item.disabled"
              :class="item.classname"
              :height="item.height"
              min-width="20"
              :max-width="item.maxwidth"
              :src="item.img"
              @click="clickNavItem(item)"
            />
         </div>
          </v-bottom-navigation>
     </div>
</template>

<script>
import SearchBar from "@/components/SearchBar";
import api from "@/api.js";
import { mapState } from "vuex";
export default {
  props: ["user"],

  components: {
    "search-bar": SearchBar
  },
  data() {
    return {
      items: [],
      dashboardBottomNavItems: [
        /**{
          name: "",
          icon: "",
          disabled: true,
          selected: false
        },
         {
             name: "search",
             icon: "mdi-magnify",
             img:"/images/search-icon_-_100x100.png",
             disabled: false,
             selected: false
         },**/


        {
          name: "dashboard",
          icon: "",
          img:"/images/thumb.png",
          activeimg:"/images/athumbview.png",
          routename:"dashboard",
          maxwidth:69,
         // height:48,
          disabled: true,
          selected: false,
          classname:"thumbview"
        },
        {
          name: "map",
          icon: "mdi-map-marker",
          img:"/images/map.png",
          activeimg:"/images/amapview.png",
          maxwidth:92,
          //height:48,
          routename:"Places",
          disabled: false,
          selected: false,
          classname:"mapview"
        }
        /*{
          name: "",
          icon: "",
          disabled: true,
          selected: false
        },
        {
          name: "",
          icon: "",
          disabled: true,
          selected: false
        }*/
      ],
      dashboardBottomNavItemsnew: [
        {
          name: "dashboard",
          icon: "",
          img:"/images/f.png",
          activeimg:"/images/athumbview.png",
          routename:"dashboard",
          maxwidth:57,
          disabled: true,
          selected: false
        },
        {
          name: "map",
          icon: "mdi-map-marker",
          img:"/images/amapview.png",
          activeimg:"/images/amapview.png",
          maxwidth:92,
          routename:"Places",
          disabled: false,
          selected: false
        }

      ],
      dashboardBottomNavItemsStreaming: [

        {
          name: "new-streaming",
          icon: "",
          img:"/images/1.png",
          activeimg:"/images/athumbview.png",
          routename:"newstreaming",
          maxwidth:'auto',
          disabled: true,
          selected: false
        },
        {
          name: "map",
          icon: "mdi-map-marker",
          img:"/images/2.png",
          activeimg:"/images/amapview.png",
          maxwidth:'auto',
          routename:"Places",
          disabled: false,
          selected: false
        },
        {
          name: "map",
          icon: "mdi-map-marker",
          img:"/images/6png.png",
          activeimg:"/images/amapview.png",
          maxwidth:'auto',
          routename:"Places",
          disabled: false,
          selected: false
        },
        {
          name: "map",
          icon: "mdi-map-marker",
          img:"/images/5.png",
          activeimg:"/images/amapview.png",
          maxwidth:'auto',
          routename:"Places",
          disabled: false,
          selected: false
        }
        /** {
          name: "map",
          icon: "mdi-map-marker",
          img:"/images/3.png",
          activeimg:"/images/amapview.png",
          maxwidth:'auto',
          routename:"Places",
          disabled: false,
          selected: false
        } **/

      ],
      liveStreamingItem: {
        name: "liveStreaming",
        icon: "",
        disabled: false,
        selected: false
      },
      stopLiveStreamingItem: {
        name: "stopLiveStreaming"
      },
      defaultItemName: "",
      selectedItem: null,
      currentUrl: location.href,
      isNewStreamingPage: false,
      isLiveStreamingPageByStreamer: false,
      isLiveStreamingPageByViewer: false,
      pageUrl:location.hostname,
      detectLocation: false,
      upHere:false
    };
  },

  mounted() {
    this.bottomNavItemsHandler();
    this.detectLocation = !!this.user.lat;
    const item='';
    if(window.location.pathname === '/dashboard'){
    /*item = this.dashboardBottomNavItems.find(
      item => item.name === this.defaultItemName
    );*/
    }else if(window.location.pathname === '/places'){
      this.isNewStreamingPage = false;
      const item = this.dashboardBottomNavItemsnew.find(
      item => item.name === this.defaultItemName
    );

     } else if(window.location.pathname === '/new-streaming'){
      this.isNewStreamingPage = true;
      const item = this.dashboardBottomNavItemsStreaming.find(
      item => item.name === this.defaultItemName
    );
    }

    this.updateCurrentNavItem(item);
  },
  watch: {

    detectLocation(value) {
      if (value) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.updateUserInfo({
              lat: position ? position.coords.latitude : "",
              lng: position ? position.coords.longitude : ""
            });
          });
        } else {
          // this.userLocation =
          //     "Geolocation is not supported by this browser.";
        }
      } else {
        this.updateUserInfo({
          lat: "",
          lng: ""
        });
      }
    },
    user: function(value) {
      if (location.href.includes("new-streaming")) {
        this.isNewStreamingPage = true;
      } else if (
        location.href.includes("live-streaming") &&
        Number(value.id) === Number(this.$route.params.id)
      ) {
        this.isLiveStreamingPageByStreamer = true;
      } else if (
        location.href.includes("live-streaming") &&
        Number(value.id) !== Number(this.$route.params.id)
      ) {
        this.isLiveStreamingPageByViewer = true;
      }
    }
  },
  methods: {
    GenerateURL(){
    var fullUrl = window.location.origin + this.$route.path;
    },
    async isStreamingPublicactive(value){
      var isstreaming=value;
      if(value=="Yes"){
        isstreaming='No';
      }else{
        isstreaming="Yes";
      }
      const datavalue = {
        is_streaming_public: isstreaming
      };
      await api.user.update(this.user.id, datavalue);
      const res = await api.auth.user();
      this.user = res.data;
      this.updateUserInfo(datavalue);

    },
    async callGeolocation(value){
      var gstatus=value;
      if(value=="Yes"){
        gstatus='No';
      }else{
        gstatus="Yes";
      }

      await api.user.update(this.user.id, {lat: "",lng: "",geolocation_enable:gstatus});
      const res = await api.auth.user();
      this.user = res.data;
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.updateUserInfo({
              lat: position ? position.coords.latitude : "",
              lng: position ? position.coords.longitude : ""
            });
          });
        } else {
          this.updateUserInfo({
          lat: "",
          lng: ""
        });
        }

      this.detectLocation=true;
        console.log(value);
    },
    updateUriByKeyword (keyword = "") {
      const query = { heading: keyword };
      this.$router.push({
        query
      });
    },
    bottomNavItemsHandler() {
      this.items = this.dashboardBottomNavItems;
      if(window.location.pathname === '/dashboard'){
        this.items = this.dashboardBottomNavItems;
    }
    else if(window.location.pathname === '/'){
        this.items = this.dashboardBottomNavItems;
    }
    else if(window.location.pathname === '/places'){
        this.items = this.dashboardBottomNavItemsnew;
    }
    else if(window.location.pathname === '/new-streaming'){
        this.items = this.dashboardBottomNavItemsStreaming;
    }
    else{
      this.items = this.dashboardBottomNavItemsnew;

    }
      if (location.href.includes("places")) {
        this.defaultItemName = "map";
      } else if (location.href.includes("video-chat")) {
        this.defaultItemName = "play";
      }
    },

    updateCurrentNavItem(item) {
      this.selectedItem = item;
      this.$store.commit("common/updateState", {
        selectedBottomNavItem: null
      });
      this.$store.commit("common/updateState", {
        selectedBottomNavItem: item
      });
    },

    clickNavItem(item) {
      this.updateCurrentNavItem(item);
      if (item.name === "play") {
        location.href = "/video-chat";
      }
      if (item.name === "dashboard") {
        location.href = "/dashboard";
      }
      if (item.name === "map") {
        location.href = "/places";
      }
      if (location.href.includes("video-chat") && item.name === "filters") {
        location.href = "/";
      }
    },
    async updateUserInfo(data) {
      await api.user.update(this.user.id, data);
    }
  }
};
</script>

<style>
.go-live-button {
  font-size: 1rem !important;
  width: 150px;
}
.v-input{
border:2px solid 3px solid #0d0c0c17;
}
.height80{
  height:80px !important;
}
.height112{
  height:80px !important;
}
.bottom-bar{
  width:100% !important;
}
.bottomico{
  background: #ced4da;
  border-radius: 50%;
  height:68px;
}
.colorcu{
  color:#949497 !important;
}
.mt14{
  margin-top:14px !important;
}
.mtn10{
  margin-top:-10px !important;
}
.actives{
    border: 2px solid blue;
    border-radius: 50px;
    background: #0000ff1c;
    height: 63px;
}
.pointer{
  cursor:pointer !important;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 12px 0 2px !important;
}
.search-bar {
    border: 2px solid;
    border-color: rgba(184,174,174,.32);
    height: 48px !important;
    margin-top: 4px  !important;
    border-radius: 20px!important;
}
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 14px 0 2px;
}
.v-text-field.v-input--dense .v-input__append-inner, .v-text-field.v-input--dense .v-input__prepend-inner {
    margin-top: 7px !important;
}
.rightimg{
    min-width: 20px;
    max-width: 87px;
    float: left;
    margin-top: 5px;
    margin-left: -10px;
}
.leftimg{
    min-width: 20px;
    max-width: 69px;
    float: left;
    margin-top: 5px;
}
</style>
